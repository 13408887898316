/**
 * Created by vinay on 19/12/18.
 */
import { isStringNullOrUndefined } from '../../constants/CommonUtil';
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { formatDate, getStartAndEndDateFromDateOperation, setTimeForDate } from '../../constants/DateUtil';
import { ADVANCE_SEARCH_OPERATORS } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const getOperation = (op) => {
    switch (op) {
    case ADVANCE_SEARCH_OPERATORS.CONTAINS: return ':';
    case ADVANCE_SEARCH_OPERATORS.EQUALS: return '=';
    default: return op;
    }
};

const getValuesBasedOnOperation = (filterVal, operation, path) => {
    console.log('kjsdfnakjsdf', filterVal, operation, path);
    if (operation === ADVANCE_SEARCH_OPERATORS.EQUALS) {
        return getStringFromObject(path, filterVal);
    }
    return filterVal;
};

const getCriteriaForDateFilter = (filter, key) => {
    if (key && isObjectValidAndNotEmpty(filter)) {
        const { startDate, endDate } = getStartAndEndDateFromDateOperation(getStringFromObject('value', filter, {}),
            getStringFromObject('operation', filter));
        console.log('jkdfhaklsdfas', filter, startDate, endDate);
        return getCombined(
            generateObjectWithCriteriaMode(
                key,
                startDate.getTime(),
                '>=',
            ),
            'AND',
            generateObjectWithCriteriaMode(
                key,
                endDate.getTime(),
                '<=',
            ),
        );
    }
    return null;
};

export const generatePurchaseOrderSearchConstruct = (filters) => {
    let searchConstruct = {};
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.SUPPLIER) && filters.SUPPLIER.value) {
            searchConstruct = generateObjectWithCriteriaMode(
                'res_partner.id',
                filters.SUPPLIER.value.id,
                getOperation(filters.SUPPLIER.operation),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STOCK_LOCATION) && filters.STOCK_LOCATION.value) {
            searchConstruct = generateObjectWithCriteriaMode(
                'stock_location.id',
                filters.STOCK_LOCATION.value.id,
                getOperation(filters.STOCK_LOCATION.operation),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STATUS) && filters.STATUS.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'purchase_order.state',
                    filters.STATUS.value,
                    getOperation(filters.STATUS.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PRODUCT) && filters.PRODUCT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'product.uuid',
                    filters.PRODUCT.value.uuid,
                    getOperation(filters.PRODUCT.operation),
                ),
            );
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'purchase_order.dateOrder',
                        formatDate(setTimeForDate(filters.fromDate), 'dd/MM/yyyy HH:mm'),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'purchase_order.dateOrder',
                        formatDate(setTimeForDate(filters.toDate, 23, 59, 59), 'dd/MM/yyyy HH:mm'),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const generateStockIntentConstruct = (filters) => {
    console.log('filterstockintent', filters);
    let searchConstruct = null;
    if (filters.fromDate && filters.toDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode(
                    'intent.createDate',
                    (setTimeForDate(filters.fromDate).getTime()),
                    '>=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    'intent.createDate',
                    (setTimeForDate(filters.toDate, 23, 59, 59).getTime()),
                    '<=',
                ),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.TYPE) && filters.TYPE.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'intent.type',
                filters.TYPE.value,
                getOperation(filters.TYPE.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.SOURCE_LOCATION)) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stockLocation.uuid',
                filters.SOURCE_LOCATION.value.uuid,
                getOperation(filters.SOURCE_LOCATION.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.INTENT_NUMBER) && filters.INTENT_NUMBER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'intent.intentNumber',
                filters.INTENT_NUMBER.value,
                getOperation(filters.INTENT_NUMBER.operation),
            ),
        );
    }
    return searchConstruct;
};

export const generateMaterialReceiveConstruct = (filters) => {
    console.log('generateMaterialReceiveConstruct', filters);
    let searchConstruct = null;
    if (filters.fromDate && filters.toDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode(
                    'material_receive.createDate',
                    setTimeForDate(filters.fromDate).getTime(),
                    '>=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    'material_receive.createDate',
                    setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                    '<=',
                ),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.SUPPLIER) && filters.SUPPLIER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'res_partner.uuid',
                filters.SUPPLIER.value.uuid,
                getOperation(filters.SUPPLIER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.LOCATION)) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_location.uuid',
                filters.LOCATION.value.uuid,
                getOperation(filters.LOCATION.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.CASH_SUPPLIER) && !isStringNullOrUndefined(filters.CASH_SUPPLIER.value)) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'material_receive.cashSupplierName',
                filters.CASH_SUPPLIER.value.toUpperCase(),
                getOperation(filters.CASH_SUPPLIER.operation),
            ),
        );
    }
    return searchConstruct;
};

export const generateSaleOrderSearchConstruct = (filters) => {
    if (isObjectValidAndNotEmpty(filters)) {
        //
    }
    return null;
};

const getAccountInvoiceConstruct = (filters) => {
    let searchConstruct = null;
    if (filters.fromDate && filters.toDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode(
                    'account_invoice.dateInvoice',
                    setTimeForDate(filters.fromDate, 0, 0, 0, 0).getTime(),
                    '>=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    'account_invoice.dateInvoice',
                    setTimeForDate(filters.toDate, 23, 59, 59, 59).getTime(),
                    '<=',
                ),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.PARTNER) && filters.PARTNER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'res_partner.id',
                filters.PARTNER.value.id,
                getOperation(filters.PARTNER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.PATIENT) && filters.PATIENT.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'person.uuid',
                getStringFromObject('value.key', filters.PATIENT),
                getOperation(filters.PATIENT.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.ACCOUNT) && filters.ACCOUNT.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account.uuid',
                filters.ACCOUNT.value.key,
                getOperation(filters.ACCOUNT.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.PRODUCT) && filters.PRODUCT.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'product.id',
                getStringFromObject('PRODUCT.value.productId', filters),
                getOperation(filters.PRODUCT.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.REFERENCE_NUMBER) && filters.REFERENCE_NUMBER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account_invoice.supplierInvoiceNumber',
                filters.REFERENCE_NUMBER.value,
                getOperation(filters.REFERENCE_NUMBER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.PURCHASE_ORDER) && filters.PURCHASE_ORDER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'purchase_order.name',
                filters.PURCHASE_ORDER.value,
                getOperation(filters.PURCHASE_ORDER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account_invoice.comment',
                filters.MEMO.value,
                getOperation(filters.MEMO.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.STATUS) && filters.STATUS.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account_invoice.state',
                filters.STATUS.value,
                getOperation(filters.STATUS.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.INVOICE_NUMBER) && filters.INVOICE_NUMBER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account_invoice.number',
                filters.INVOICE_NUMBER.value,
                getOperation(filters.INVOICE_NUMBER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.DUE_DATE) && filters.DUE_DATE.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.DUE_DATE, 'account_invoice.dateDue'),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
        const operation = getStringFromObject('AMOUNT.operation', filters);
        if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'account_invoice.amountTotal',
                        getStringFromObject('AMOUNT.value.from', filters, 0),
                        '>',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'account_invoice.amountTotal',
                        getStringFromObject('AMOUNT.value.to', filters, 0),
                        '<',
                    ),
                ),
            );
        } else {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_invoice.amountTotal',
                    getStringFromObject('AMOUNT.value', filters, 0),
                    getOperation(operation),
                ),
            );
        }
    }
    if (isObjectValidAndNotEmpty(filters.CASH_SUPPLIER) && !isStringNullOrUndefined(filters.CASH_SUPPLIER.value)) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'account_invoice.cashPartnerName',
                filters.CASH_SUPPLIER.value.toUpperCase(),
                getOperation(filters.CASH_SUPPLIER.operation),
            ),
        );
    }
    return searchConstruct;
};

export const getPurchaseBillSearchConstruct = (filters = {}) => {
    let searchConstruct = generateObjectWithCriteriaMode('account_invoice.type', 'in_invoice', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getAccountInvoiceConstruct(filters),
    );
    const excludeMemoConstruct = generateObjectWithCriteriaMode('account_invoice.paymentType', 'CASH_MEMO', '!=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        excludeMemoConstruct,
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getIssueInvoiceSearchConstruct = (filters = {}) => {
    let searchConstruct = generateObjectWithCriteriaMode('account_invoice.type', 'out_invoice', '=');
    searchConstruct =
        getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('res_partner.payer', true, '='),
        );
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getAccountInvoiceConstruct(filters),
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

// export const getCreditOrDebitMemoSearchConstruct = (filters = {}) => {
//     let searchConstruct = generateObjectWithCriteriaMode('account_invoice.type', 'in_refund', '=');
//     searchConstruct = getCombined(
//         searchConstruct,
//         'AND',
//         generateObjectWithCriteriaMode('account_invoice.paymentType', 'CASH_MEMO', '='),
//     );
//     searchConstruct = getCombined(
//         searchConstruct,
//         'AND',
//         getAccountInvoiceConstruct(filters),
//     );
//     return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
// };

export const getPayerInvoiceSearchConstruct = (filters = {}, invoiceType = 'out_invoice') => {
    let searchConstruct = generateObjectWithCriteriaMode('account_invoice.type', invoiceType, '=');
    const customerInvoiceConstruct = getCombined(
        generateObjectWithCriteriaMode('account_invoice.paymentType', 'CASH', '='),
        'OR',
        generateObjectWithCriteriaMode('account_invoice.paymentType', 'ASSOCIATION_CREDIT', '='),
    );
    searchConstruct = getCombined(
        customerInvoiceConstruct,
        'AND',
        searchConstruct,
    );
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getAccountInvoiceConstruct(filters),
    );
    const excludeReceivedDEbitMemo = generateObjectWithCriteriaMode('account_invoice.paymentType', 'CASH_MEMO', '!=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        excludeReceivedDEbitMemo,
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getExpenseSearchConstruct = (filters, type) => {
    let searchConstruct = {};
    if (type) {
        searchConstruct = generateObjectWithCriteriaMode(
            'expense.type',
            type,
            '=',
        );
    }
    if (isObjectValidAndNotEmpty(filters)) {
        console.log('fix these filters', filters);
        if (isObjectValidAndNotEmpty(filters.PAYEE) && filters.PAYEE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.id',
                    filters.PAYEE.value.id,
                    getOperation(filters.PAYEE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.EMPLOYEE) && filters.EMPLOYEE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.id',
                    getStringFromObject('partnerId', filters.EMPLOYEE.value),
                    getOperation(filters.EMPLOYEE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.ACCOUNT) && filters.ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_account.uuid',
                    filters.ACCOUNT.value.key,
                    getOperation(filters.ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.CATEGORY) && filters.CATEGORY.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'category.uuid',
                    filters.CATEGORY.value.key,
                    getOperation(filters.CATEGORY.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'expense.memo',
                    filters.MEMO.value,
                    getOperation(filters.MEMO.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.REFERENCE_NUMBER) && filters.REFERENCE_NUMBER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'expense.referenceNumber',
                    filters.REFERENCE_NUMBER.value,
                    getOperation(filters.REFERENCE_NUMBER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STATE) && filters.STATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'expense.state',
                    filters.STATE.value,
                    getOperation(filters.STATE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'expense.total',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'expense.total',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'expense.total',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'expense.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'expense.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};


const getAccountVoucherConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.PARTNER) && filters.PARTNER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.id',
                    filters.PARTNER.value.id,
                    getOperation(filters.PARTNER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.REFERENCE_NUMBER) && filters.REFERENCE_NUMBER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_voucher.reference',
                    filters.REFERENCE_NUMBER.value,
                    getOperation(filters.REFERENCE_NUMBER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.INVOICE_NUMBER) && filters.INVOICE_NUMBER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_voucher_line.number',
                    filters.INVOICE_NUMBER.value,
                    getOperation(filters.INVOICE_NUMBER.operation),
                ),
            );
        }
        // todo change to search in cheque details details once backend has it
        if (isObjectValidAndNotEmpty(filters.CHEQUE_NUMBER) && filters.CHEQUE_NUMBER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_voucher.reference',
                    filters.CHEQUE_NUMBER.value,
                    getOperation(filters.CHEQUE_NUMBER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PAYMENT_MODE) && filters.PAYMENT_MODE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_voucher.modeOfPayment',
                    filters.PAYMENT_MODE.value,
                    getOperation(filters.PAYMENT_MODE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.DUE_DATE) && filters.DUE_DATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.DUE_DATE, 'account_voucher.dateDue'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_voucher.comment',
                    filters.MEMO.value,
                    getOperation(filters.MEMO.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'account_voucher.amount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'account_voucher.amount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'account_voucher.amount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'account_voucher.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'account_voucher.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return searchConstruct;
};

export const getBillPaymentConstruct = (filters) => {
    let searchConstruct = getCombined(
        generateObjectWithCriteriaMode('account_voucher.type', 'payment', '='),
        'OR',
        generateObjectWithCriteriaMode('account_voucher.type', 'receive', '='),
    );
    searchConstruct = getCombined(
        getAccountVoucherConstruct(filters),
        'AND',
        searchConstruct,
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getPaymentReceivedConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('account_voucher.type', 'receipt', '=');
    searchConstruct = getCombined(
        getAccountVoucherConstruct(filters),
        'AND',
        searchConstruct,
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getStockPickingConstruct = (filters) => {
    let searchConstruct = null;
    if (filters.fromDate && filters.toDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode(
                    'stock_picking.createDate',
                    setTimeForDate(filters.fromDate, 0, 0, 0, 0).getTime(),
                    '>=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_picking.createDate',
                    setTimeForDate(filters.toDate, 23, 59, 59, 59).getTime(),
                    '<=',
                ),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.SOURCE_LOCATION) && filters.SOURCE_LOCATION.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'source_location.id',
                filters.SOURCE_LOCATION.value.id,
                getOperation(filters.SOURCE_LOCATION.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.DESTINATION_LOCATION) && filters.DESTINATION_LOCATION.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'destination_location.id',
                filters.DESTINATION_LOCATION.value.id,
                getOperation(filters.DESTINATION_LOCATION.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.PRODUCT) && filters.PRODUCT.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'product.id',
                getStringFromObject('PRODUCT.value.productId', filters),
                getOperation(filters.PRODUCT.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.TYPE) && filters.TYPE.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_picking.type',
                getStringFromObject('TYPE.value', filters),
                getOperation(filters.TYPE.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.STATUS) && filters.STATUS.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_picking.state',
                filters.STATUS.value,
                getOperation(filters.STATUS.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.BATCH) && filters.BATCH.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_production_lot.id',
                filters.BATCH.value.batchId,
                getOperation(filters.BATCH.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_picking.note',
                filters.MEMO.value,
                getOperation(filters.MEMO.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.SUPPLIER) && filters.SUPPLIER.value) {
        console.log('asd-as0dia-s0dia-0dias[d-0as', filters.SUPPLIER);
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'res_partner.uuid',
                filters.SUPPLIER.value.uuid,
                getOperation(filters.SUPPLIER.operation),
            ),
        );
    }
    if (isObjectValidAndNotEmpty(filters.REFERENCE_NUMBER) && filters.REFERENCE_NUMBER.value) {
        console.log('asd-as0dia-s0dia-0dias[d-0as', filters.REFERENCE_NUMBER);
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'stock_picking.name',
                filters.REFERENCE_NUMBER.value,
                getOperation(filters.REFERENCE_NUMBER.operation),
            ),
        );
    }
    return searchConstruct;
};

export const getAddStockConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('stock_picking.type', 'add_stock', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getStockPickingConstruct(filters),
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getInventoryAdjustmentConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('stock_picking.type', 'stock_adjustment', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getStockPickingConstruct(filters),
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getInternalMoveConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('stock_picking.type', 'internal', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getStockPickingConstruct(filters),
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getReceiveStockConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('stock_picking.type', 'in', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getStockPickingConstruct(filters),
    );
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getReturnStockConstruct = (filters) => {
    console.log('kdjafhjjk', filters);
    let searchConstruct = generateObjectWithCriteriaMode('stock_picking.type', 'return_stock', '=');
    searchConstruct = getCombined(
        searchConstruct,
        'AND',
        getStockPickingConstruct(filters),
    );
    if (isObjectValidAndNotEmpty(filters.PARTNER) && filters.PARTNER.value) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(
                'res_partner.id',
                getStringFromObject('value.id', filters.PARTNER, ''),
                getOperation(filters.PARTNER.operation),
            ),
        );
    }
    console.log('kdjafhjjk', searchConstruct);
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getFundTransferConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.ACCOUNT_FROM) && filters.ACCOUNT_FROM.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_account_from.id',
                    getStringFromObject('value.key', filters.ACCOUNT_FROM, ''),
                    getOperation(filters.ACCOUNT_FROM.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.ACCOUNT_TO) && filters.ACCOUNT_TO.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_account_to.id',
                    getStringFromObject('value.key', filters.ACCOUNT_TO, ''),
                    getOperation(filters.ACCOUNT_TO.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'fund_transfer.memo',
                    filters.MEMO.value,
                    getOperation(filters.MEMO.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'fund_transfer.transferAmount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'fund_transfer.transferAmount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'fund_transfer.transferAmount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'fund_transfer.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'fund_transfer.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getAccountMoveConstruct = (filters) => {
    let searchConstruct = null;
    console.log('dhasjkdfgakjsdgfaksjg', filters);
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.ACCOUNT_JOURNAL) && filters.ACCOUNT_JOURNAL.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_journal.id',
                    getStringFromObject('value.id', filters.ACCOUNT_JOURNAL, ''),
                    getOperation(filters.ACCOUNT_JOURNAL.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.MEMO) && filters.MEMO.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_move.narration',
                    getStringFromObject('value', filters.MEMO, ''),
                    getOperation(filters.MEMO.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STATE) && filters.STATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_move.state',
                    getStringFromObject('value', filters.STATE, ''),
                    getOperation(filters.STATE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.ACCOUNT_PERIOD) && filters.ACCOUNT_PERIOD.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_period.id',
                    getStringFromObject('value.id', filters.ACCOUNT_PERIOD, ''),
                    getOperation(filters.ACCOUNT_PERIOD.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PARTNER) && filters.PARTNER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_move.partnerId',
                    getStringFromObject('value.id', filters.PARTNER, ''),
                    getOperation(filters.PARTNER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.NAME) && filters.NAME.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_move.name',
                    filters.NAME.value,
                    getOperation(filters.NAME.operation),
                ),
            );
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'account_move.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'account_move.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};


export const getAnalyticalAccountLinesConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.ANALYTICAL_ACCOUNT) && filters.ANALYTICAL_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_analytic_account.id',
                    getStringFromObject('value.key', filters.ANALYTICAL_ACCOUNT, ''),
                    getOperation(filters.ANALYTICAL_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.GENERAL_ACCOUNT) && filters.GENERAL_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'general_account.uuid',
                    getStringFromObject('value.key', filters.GENERAL_ACCOUNT, ''),
                    getOperation(filters.GENERAL_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.VISIT_TYPE) && filters.VISIT_TYPE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_analytic_line.visitType',
                    getStringFromObject('value', filters.VISIT_TYPE, ''),
                    getOperation(filters.VISIT_TYPE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PARTNER) && filters.PARTNER.value) {
            const operation = getStringFromObject('operation', filters.PARTNER, '');
            const key = operation === ADVANCE_SEARCH_OPERATORS.EQUALS ? 'res_partner.id' : 'res_partner.name';
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    key,
                    getValuesBasedOnOperation(getStringFromObject('value', filters.PARTNER, ''), operation, 'id'),
                    getOperation(operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.DOCTOR) && filters.DOCTOR.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'line.provider.id',
                    getStringFromObject('value.key', filters.DOCTOR, ''),
                    getOperation(filters.DOCTOR.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PROVIDER) && filters.PROVIDER.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'orderer.uuid',
                    getStringFromObject('value.key', filters.PROVIDER, ''),
                    getOperation(filters.PROVIDER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PRODUCT) && filters.PRODUCT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'product_product.id',
                    getStringFromObject('value.productId', filters.PRODUCT, ''),
                    getOperation(filters.PRODUCT.operation),
                ),
            );
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'account_analytic_line.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'account_analytic_line.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};
export const getAdvancedSalaryLinesConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('employeeAdvance.type', 'advance', '=');
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.EMPLOYEE) && filters.EMPLOYEE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'hrEmployee.nameRelated',
                    getStringFromObject('value.value', filters.EMPLOYEE, ''),
                    getOperation(filters.EMPLOYEE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.FROM_ACCOUNT) && filters.FROM_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'fromAccount.uuid',
                    getStringFromObject('value.key', filters.FROM_ACCOUNT, ''),
                    getOperation(filters.FROM_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STATE) && filters.STATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'employeeAdvance.state',
                    getStringFromObject('value', filters.STATE, ''),
                    getOperation(filters.STATE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.totalAmount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.totalAmount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.totalAmount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (isObjectValidAndNotEmpty(filters.BALANCE)) {
            const operation = getStringFromObject('BALANCE.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.amountResidual',
                            getStringFromObject('BALANCE.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.amountResidual',
                            getStringFromObject('BALANCE.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.amountResidual',
                        getStringFromObject('BALANCE.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    console.log('cnakn,xzmcnsdf', searchConstruct);
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};
export const getPettyCashLinesConstruct = (filters) => {
    let searchConstruct = generateObjectWithCriteriaMode('employeeAdvance.type', 'petty', '=');
    if (isObjectValidAndNotEmpty(filters)) {
        if (isObjectValidAndNotEmpty(filters.EMPLOYEE) && filters.EMPLOYEE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'hrEmployee.nameRelated',
                    getStringFromObject('value.value', filters.EMPLOYEE, ''),
                    getOperation(filters.EMPLOYEE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.FROM_ACCOUNT) && filters.FROM_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'fromAccount.uuid',
                    getStringFromObject('value.key', filters.FROM_ACCOUNT, ''),
                    getOperation(filters.FROM_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.STATE) && filters.STATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'employeeAdvance.state',
                    getStringFromObject('value', filters.STATE, ''),
                    getOperation(filters.STATE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.totalAmount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.totalAmount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.totalAmount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (isObjectValidAndNotEmpty(filters.BALANCE)) {
            const operation = getStringFromObject('BALANCE.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.amountResidual',
                            getStringFromObject('BALANCE.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'employeeAdvance.amountResidual',
                            getStringFromObject('BALANCE.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.amountResidual',
                        getStringFromObject('BALANCE.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'employeeAdvance.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    console.log('cnakn,xzmcnsdf', searchConstruct);
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getPrepaidExpenseConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const name = getStringFromObject('NAME.value', filters);
        if (name) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'prepaid_expense.name',
                    name,
                    getOperation(filters.NAME.operation),
                ),
            );
        }
        const supplierUuid = getStringFromObject('PARTNER.value.uuid', filters);
        if (supplierUuid) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.uuid',
                    supplierUuid,
                    getOperation(filters.PARTNER.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.CREDIT_ACCOUNT) && filters.CREDIT_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'credit_account.uuid',
                    getStringFromObject('value.key', filters.CREDIT_ACCOUNT, ''),
                    getOperation(filters.CREDIT_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.EXPENSE_ACCOUNT) && filters.EXPENSE_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'expense_account.uuid',
                    getStringFromObject('value.key', filters.EXPENSE_ACCOUNT, ''),
                    getOperation(filters.EXPENSE_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PREPAID_ACCOUNT) && filters.PREPAID_ACCOUNT.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'prepaid_account.uuid',
                    getStringFromObject('value.key', filters.PREPAID_ACCOUNT, ''),
                    getOperation(filters.PREPAID_ACCOUNT.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PAYMENT_DATE) && filters.PAYMENT_DATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.PAYMENT_DATE, 'prepaid_expense.paymentDate'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.START_DATE) && filters.START_DATE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.START_DATE, 'prepaid_expense.date'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.PAYMENT_MODE) && filters.PAYMENT_MODE.value) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'prepaid_expense.paymentMethod',
                    filters.PAYMENT_MODE.value,
                    getOperation(filters.PAYMENT_MODE.operation),
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'prepaid_expense.totalAmount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'prepaid_expense.totalAmount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'prepaid_expense.totalAmount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'prepaid_expense.paymentDate',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'prepaid_expense.paymentDate',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    console.log('cnakn,xzmcnsdf', searchConstruct);
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getCashCloseConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const employeeUuid = getStringFromObject('USER.value.key', filters);
        if (employeeUuid) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'employee.uuid',
                    employeeUuid,
                    getOperation(filters.USER.operation),
                ),
            );
        }
        const date = getStringFromObject('DATE.value', filters);
        if (date) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.DATE, 'cash_close.date'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'cash_close.amount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'cash_close.amount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'cash_close.amount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'cash_close.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'cash_close.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getConsolidatedCashCloseConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const employeeUuid = getStringFromObject('USER.value.key', filters);
        if (employeeUuid) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'employee.uuid',
                    employeeUuid,
                    getOperation(filters.USER.operation),
                ),
            );
        }
        const date = getStringFromObject('DATE.value', filters);
        if (date) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.DATE, 'consolidated_cash_close.date'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'consolidated_cash_close.amount',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'consolidated_cash_close.amount',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'consolidated_cash_close.amount',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'consolidated_cash_close.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'consolidated_cash_close.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getPayOnAccountConstruct = (filters) => {
    let searchConstruct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const employeeUuid = getStringFromObject('PARTNER.value.uuid', filters);
        if (employeeUuid) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.uuid',
                    employeeUuid,
                    getOperation(filters.PARTNER.operation),
                ),
            );
        }
        const creditAccount = getStringFromObject('CREDIT_ACCOUNT.value.key', filters);
        if (creditAccount) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'account_account.uuid',
                    creditAccount,
                    getOperation(filters.CREDIT_ACCOUNT.operation),
                ),
            );
        }
        const name = getStringFromObject('NAME.value', filters);
        if (name && name.trim()) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'paid_on_account.name',
                    name.trim(),
                    getOperation(filters.NAME.operation),
                ),
            );
        }
        const type = getStringFromObject('TYPE.value', filters);
        if (type) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode(
                    'paid_on_account.type',
                    type,
                    getOperation(filters.TYPE.operation),
                ),
            );
        }
        const date = getStringFromObject('DATE.value', filters);
        if (date) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCriteriaForDateFilter(filters.DATE, 'paid_on_account.date'),
            );
        }
        if (isObjectValidAndNotEmpty(filters.AMOUNT)) {
            const operation = getStringFromObject('AMOUNT.operation', filters);
            if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    getCombined(
                        generateObjectWithCriteriaMode(
                            'paid_on_account.amountAdvance',
                            getStringFromObject('AMOUNT.value.from', filters, 0),
                            '>',
                        ),
                        'AND',
                        generateObjectWithCriteriaMode(
                            'paid_on_account.amountAdvance',
                            getStringFromObject('AMOUNT.value.to', filters, 0),
                            '<',
                        ),
                    ),
                );
            } else {
                searchConstruct = getCombined(
                    searchConstruct,
                    'AND',
                    generateObjectWithCriteriaMode(
                        'paid_on_account.amountAdvance',
                        getStringFromObject('AMOUNT.value', filters, 0),
                        getOperation(operation),
                    ),
                );
            }
        }
        if (filters.fromDate && filters.toDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'paid_on_account.date',
                        setTimeForDate(filters.fromDate).getTime(),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'paid_on_account.date',
                        setTimeForDate(filters.toDate, 23, 59, 59).getTime(),
                        '<=',
                    ),
                ),
            );
        }
    }
    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};
