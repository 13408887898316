/**
 * Created by vinay on 22/12/18.
 */
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty,
    roundedValue,
    sortArrayOfObjectsByFieldValue,
    valueToFixedTwoDigits,
} from '../constants/CommonUtil';
import { formatDate, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const INVOICE_LINE_UI_OBJECT = {
    uuid: '',
    name: '',
    origin: '',
    description: '',
    sequence: '',
    product: null,
    uom: null,
    purchaseOrderLineUuid: '',
    quantity: '',
    bonus: '',
    rate: '',
    amount: '',
    discount: '',
    discountAmount: '',
    total: '',
    totalTax: '',
    taxes: [],
};
export const INVOICE_UI_OBJECT = {
    uuid: '',
    state: '',
    shopId: null,
    residualAmount: null,
    supplierInvoiceNumber: null,
    warehouse: null,
    name: null,
    origin: null,
    comment: '',
    purchaseOrder: null,
    supplier: null,
    dueDate: getDateInYYYYMMDDFormat(new Date()),
    dateInvoice: getDateInYYYYMMDDFormat(new Date()),
    discountHead: null,
    discountAmount: 0,
    discountPer: 0,
    discountInPercentage: false,
    discountAccount: null,
    amountTax: 0,
    amountUntaxed: 0,
    subTotal: 0,
    amountTotal: 0,
    isCashPurchaseOrSale: false,
    cashPartnerName: '',
    cashPartnerIdNumber: '',
    cashPartnerSubCompany: null,
    accountInvoiceLines: [cloneDeep(INVOICE_LINE_UI_OBJECT)],
};

export const ACCOUNT_INVOICE_OPERATION = {
    DRAFT: 'draft',
    PAID: 'paid',
    CONFIRM: 'open',
    CANCEL: 'cancel',
};

export const getUiObjectFromPurchaseOrder = (purchaseOrder = {}) => {
    console.log('abfjafjafklafa', purchaseOrder);
    const now = formatDate(new Date(), 'yyyy-MM-dd');
    const nowWithTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
    const uiObject = cloneDeep(INVOICE_UI_OBJECT);
    uiObject.comment = '';
    uiObject.purchaseOrder = { key: purchaseOrder.uuid, value: purchaseOrder.name };
    uiObject.supplier = purchaseOrder.supplier;
    uiObject.subVendor = purchaseOrder.subVendor || false;
    uiObject.dueDate = nowWithTime;
    uiObject.dateInvoice = now;
    const discount = NumberOf(getStringFromObject('discount', purchaseOrder));
    // uiObject.discountPer = NumberOf(getStringFromObject('discountPercent', purchaseOrder));
    uiObject.discountAccount = getStringFromObject('discountHead', purchaseOrder, null);
    uiObject.amountTax = 0;
    uiObject.amountUntaxed = 0;
    const invoiceLines = [];
    let subTotal = 0;
    let total = 0;
    if (isArrayValidAndNotEmpty(purchaseOrder.purchaseOrderLines)) {
        purchaseOrder.purchaseOrderLines.forEach((line, index) => {
            const quantity = NumberOf(line.quantity);
            const rate = NumberOf(line.rate);
            const lineSubTotal = NumberOf(line.amount);
            const discount1 = NumberOf(line.discount);
            const discount2 = NumberOf(line.discount2);
            const discountAmount1 = roundedValue(lineSubTotal * (discount1 / 100));
            const amountAfterDisc1 = lineSubTotal - discountAmount1;
            const discountAmount2 = roundedValue(amountAfterDisc1 * (discount2 / 100));

            // const discountAmount = roundedValue(lineSubTotal * (discountPerc / 100));
            // const amount = lineSubTotal - discountAmount;
            const amount = lineSubTotal - (discountAmount1 + discountAmount2);

            let totalTax = 0;
            if (isArrayValidAndNotEmpty(line.taxes)) {
                line.taxes.forEach((tax) => {
                    totalTax += (amount * NumberOf(tax.amount));
                });
            }
            totalTax = roundedValue(totalTax);
            const lineTotal = amount + totalTax;
            subTotal += lineTotal;
            total = subTotal;
            invoiceLines.push({
                ...line,
                quantity,
                discount: discount1,
                discount2,
                discountAmount: valueToFixedTwoDigits(discountAmount1),
                discountAmount2: valueToFixedTwoDigits(discountAmount2),
                rate: valueToFixedTwoDigits(rate),
                amount: valueToFixedTwoDigits(lineSubTotal),
                amountBeforeTax: valueToFixedTwoDigits(amount),
                total: valueToFixedTwoDigits(lineTotal),
                purchaseOrderLineUuid: getStringFromObject('orderLineUuid', line, line.uuid),
                totalTax: valueToFixedTwoDigits(totalTax),
                sequence: line.sequence || index,
            });
        });
    }
    // const discount = subTotal * (uiObject.discountPer / 100);
    uiObject.discountAmount = NumberOf(valueToFixedTwoDigits(discount));
    uiObject.subTotal = NumberOf(valueToFixedTwoDigits(NumberOf(subTotal)));
    uiObject.amountTotal = NumberOf(valueToFixedTwoDigits(NumberOf(total) - discount));
    uiObject.accountInvoiceLines = sortArrayOfObjectsByFieldValue(invoiceLines, 'sequence');
    return uiObject;
};

export const getAccountInvoiceFromUiObject = (uiObject) => {
    const accountInvoice = {};
    accountInvoice.state = getStringFromObject('state', uiObject);
    accountInvoice.uuid = uiObject.uuid;
    accountInvoice.isService = uiObject.isService || false;
    accountInvoice.subCompany = getStringFromObject('subCompany.key', uiObject) || null;
    accountInvoice.purchaseOrder = uiObject.purchaseOrder;
    accountInvoice.comment = uiObject.comment;
    accountInvoice.partnerUuid = uiObject.supplier.uuid;
    accountInvoice.patientName = uiObject.supplier.name;
    accountInvoice.subVendor = uiObject.subVendor || false;
    accountInvoice.paymentTerm = uiObject.terms;
    accountInvoice.paymentType = 'CASH';
    if (uiObject.dueDate) {
        accountInvoice.dueDate = `${uiObject.dueDate} 00:00:00`;
    }
    accountInvoice.supplierInvoiceNumber = uiObject.supplierInvoiceNumber;
    accountInvoice.referenceType = '';
    accountInvoice.reconciled = null;
    accountInvoice.type = 'out_invoice';
    if (uiObject.dateInvoice) {
        accountInvoice.dateInvoice = `${uiObject.dateInvoice} 00:00:00`;
    }
    accountInvoice.discountAccount = getStringFromObject('discountAccount', uiObject, null);
    const accountInvoiceLines = [];
    let amountUnTaxed = 0;
    let amountTotal = 0;
    let totalTax = 0;
    const uiLines = uiObject.accountInvoiceLines || [];
    if (isArrayValidAndNotEmpty(uiLines)) {
        let sequence = 0;
        uiLines.forEach((uiLine) => {
            console.log('this is uiLine', uiLine);
            if (uiLine.product && uiLine.product.uuid) {
                console.log('this is uiLine', uiLine);
                const invoiceLine = {};
                sequence += 1;
                invoiceLine.uuid = uiLine.uuid;
                invoiceLine.name = uiLine.name;
                invoiceLine.origin = uiLine.origin;
                invoiceLine.purchaseOrderLineUuid = uiLine.purchaseOrderLineUuid;
                invoiceLine.uom = uiLine.uom;
                invoiceLine.description = uiLine.description;
                invoiceLine.productUuid = uiLine.product.uuid;
                invoiceLine.batchName = null;
                invoiceLine.expiryDate = null;
                invoiceLine.quantity = uiLine.quantity;
                invoiceLine.priceUnit = uiLine.rate;
                invoiceLine.discount = uiLine.discount;
                invoiceLine.discount2 = uiLine.discount2;
                invoiceLine.priceSubtotal = valueToFixedTwoDigits(NumberOf(uiLine.amountBeforeTax));
                invoiceLine.bonus = uiLine.bonus;
                invoiceLine.partnerId = null;
                if (isObjectValidAndNotEmpty(uiLine.assetCategory)) {
                    invoiceLine.assetCategory = { key: uiLine.assetCategory.id, value: uiLine.assetCategory.name };
                }
                invoiceLine.insurancePercent = null;
                invoiceLine.insuranceFlat = null;
                invoiceLine.insuranceAmount = null;
                invoiceLine.taxAccountDetails = uiLine.taxes;
                invoiceLine.sequence = sequence;
                amountUnTaxed += NumberOf(invoiceLine.priceSubtotal);
                amountTotal += NumberOf(uiLine.total);
                totalTax += NumberOf(uiLine.totalTax);
                accountInvoiceLines.push(invoiceLine);
            }
            console.log('final this is', accountInvoiceLines);
        });
    }
    accountInvoice.amountUntaxed = valueToFixedTwoDigits(amountUnTaxed);
    accountInvoice.amountTax = valueToFixedTwoDigits(totalTax);

    let discountAmount = NumberOf(getStringFromObject('discountAmount', uiObject, null));
    const discountPer = NumberOf(getStringFromObject('discountPer', uiObject, 0));
    if (discountPer > 0) {
        discountAmount = roundedValue(amountTotal * (discountPer / 100));
    }
    accountInvoice.discount = discountAmount;
    accountInvoice.discountAmount = discountAmount;
    accountInvoice.amountTotal = valueToFixedTwoDigits(amountTotal - NumberOf(accountInvoice.discount));
    accountInvoice.accountInvoiceLineDtoList = accountInvoiceLines;
    return accountInvoice;
};

export const getUiObjectFromAccountInvoiceResponse = (accountInvoice) => {
    const uiObject = cloneDeep(INVOICE_UI_OBJECT);
    uiObject.uuid = accountInvoice.uuid;
    uiObject.state = accountInvoice.state;
    uiObject.shopId = accountInvoice.shopId;
    uiObject.residualAmount = accountInvoice.residualAmount;
    uiObject.origin = accountInvoice.origin;
    uiObject.name = accountInvoice.name;
    uiObject.purchaseOrder = accountInvoice.purchaseOrder;
    uiObject.comment = accountInvoice.comment;
    uiObject.supplierInvoiceNumber = accountInvoice.supplierInvoiceNumber;
    uiObject.number = accountInvoice.number;
    if (accountInvoice.isCashPurchaseOrSale) {
        uiObject.cashPartnerName = accountInvoice.cashPartnerName || '';
        uiObject.cashPartnerIdNumber = accountInvoice.cashPartnerIdNumber || '';
        uiObject.cashPartnerSubCompany = {
            key: accountInvoice.cashPartnerSubCompany || '',
            value: accountInvoice.cashPartnerSubCompany || '',
        };
    } else {
        uiObject.supplier = { uuid: accountInvoice.partnerUuid, name: accountInvoice.patientName };
    }
    uiObject.warehouse = accountInvoice.warehouse;
    uiObject.dueDate = formatDate(accountInvoice.dueDateInMillis, 'yyyy-MM-dd');
    uiObject.dateInvoice = formatDate(accountInvoice.dateInvoiceInMillis, 'yyyy-MM-dd');
    uiObject.discountAmount = NumberOf(accountInvoice.discount);
    // uiObject.discountPer = NumberOf(accountInvoice.discountPer);
    uiObject.discountAccount = accountInvoice.discountAccount;
    uiObject.amountTax = accountInvoice.amountTax;
    uiObject.amountUntaxed = accountInvoice.amountUntaxed;
    uiObject.subTotal = accountInvoice.subTotal;
    uiObject.amountTotal = accountInvoice.amountTotal;
    uiObject.terms = accountInvoice.paymentTerm;
    uiObject.goodsReceived = accountInvoice.goodsReceived;
    uiObject.isCashPurchaseOrSale = accountInvoice.isCashPurchaseOrSale || false;
    uiObject.subVendor = accountInvoice.subVendor || false;
    uiObject.subCompany = {
        key: accountInvoice.subCompany || false,
        value: accountInvoice.subCompany || false,
    };

    const uiLines = [];
    uiObject.isService = false;
    if (isArrayValidAndNotEmpty(accountInvoice.accountInvoiceLineDtoList)) {
        accountInvoice.accountInvoiceLineDtoList.forEach((line, index) => {
            const uiLine = {};
            uiLine.uuid = line.uuid;
            uiLine.name = line.name;
            uiObject.isService = uiObject.isService || !!line.service;
            uiLine.origin = line.origin;
            uiLine.description = line.description;
            uiLine.product = { uuid: line.productUuid, productName: line.name };
            if (isObjectValidAndNotEmpty(line.assetCategory)) {
                uiLine.assetCategory = { id: line.assetCategory.key, name: line.assetCategory.value };
            }
            uiLine.uom = line.uom;
            uiLine.purchaseOrderLineUuid = line.purchaseOrderLineUuid;
            uiLine.quantity = NumberOf(line.quantity);
            uiLine.bonus = line.bonus;
            uiLine.discount = NumberOf(line.discount);
            uiLine.discount2 = NumberOf(line.discount2);
            uiLine.rate = NumberOf(line.priceUnit);
            const amount = uiLine.quantity * uiLine.rate;
            uiLine.discountAmount = valueToFixedTwoDigits(amount * (uiLine.discount / 100));
            uiLine.discountAmount2 = valueToFixedTwoDigits((amount - uiLine.discountAmount) * (uiLine.discount2 / 100));
            uiLine.amount = valueToFixedTwoDigits(amount);
            uiLine.amountBeforeTax = valueToFixedTwoDigits(NumberOf(uiLine.amount) - (NumberOf(uiLine.discountAmount) + NumberOf(uiLine.discountAmount2)));
            uiLine.taxes = line.taxAccountDetails;
            uiLine.sequence = line.sequence || index;
            let totalTax = 0;
            if (isArrayValidAndNotEmpty(line.taxAccountDetails)) {
                totalTax = 0;
                line.taxAccountDetails.forEach((tax) => {
                    totalTax += (NumberOf(uiLine.amountBeforeTax) * NumberOf(tax.amount));
                });
            }
            totalTax = roundedValue(NumberOf(totalTax));
            uiLine.total = valueToFixedTwoDigits(NumberOf(uiLine.amountBeforeTax) + NumberOf(totalTax));
            uiLine.totalTax = totalTax;
            uiLines.push(uiLine);
        });
    }
    uiObject.accountInvoiceLines = sortArrayOfObjectsByFieldValue(uiLines, 'sequence');
    return uiObject;
};

export const getUiObjectFromMaterialReceive = (materialReceive) => {
    console.log('getUiObjectFromMaterialReceive', materialReceive);
    const uiObject = cloneDeep(INVOICE_UI_OBJECT);
    uiObject.supplier = materialReceive.supplier;
    const discount = NumberOf(getStringFromObject('discount', materialReceive));
    // uiObject.discountPer = NumberOf(getStringFromObject('discountPercent', purchaseOrder));
    uiObject.discountAccount = getStringFromObject('discountHead', materialReceive, null);
    uiObject.discount = getStringFromObject('discount', materialReceive, null);
    uiObject.cashPartnerSubCompany = getStringFromObject('cashSupplierSubCompany', materialReceive) || null;
    uiObject.amountTax = 0;
    uiObject.amountUntaxed = 0;
    uiObject.isCashPurchaseOrSale = materialReceive.isCashPurchaseOrSale || false;
    uiObject.cashPartnerName = materialReceive.cashSupplierName || '';
    uiObject.cashPartnerIdNumber = materialReceive.cashSupplierVatNumber || '';
    uiObject.subVendor = materialReceive.subVendor || false;
    const invoiceLines = [];
    let subTotal = 0;
    let total = 0;

    if (isArrayValidAndNotEmpty(materialReceive.receiveMaterialLines)) {
        materialReceive.receiveMaterialLines.forEach((line) => {
            console.log('receiveMaterialLines===>', line);
            const quantity = NumberOf(line.quantity);
            const rate = NumberOf(line.price);
            const lineSubTotal = rate * quantity; // NumberOf(line.amount);
            const discount1 = NumberOf(line.discount1);
            const discount2 = NumberOf(line.discount2);
            const discountAmount1 = roundedValue(lineSubTotal * (discount1 / 100));
            const amountAfterDisc1 = lineSubTotal - discountAmount1;
            const discountAmount2 = roundedValue(amountAfterDisc1 * (discount2 / 100));
            const amount = lineSubTotal - (discountAmount1 + discountAmount2);
            let totalTax = 0;
            if (isArrayValidAndNotEmpty(line.taxes)) {
                line.taxes.forEach((tax) => {
                    totalTax += (amount * NumberOf(tax.amount));
                });
            }
            const lineTotal = amount + totalTax;
            subTotal += lineTotal;
            total = subTotal;
            const lineDto = {
                ...line,
                quantity,
                discount: discount1,
                discount2,
                discountAmount: valueToFixedTwoDigits(discountAmount1),
                discountAmount2: valueToFixedTwoDigits(discountAmount2),
                rate: valueToFixedTwoDigits(rate),
                amount: valueToFixedTwoDigits(lineSubTotal),
                amountBeforeTax: valueToFixedTwoDigits(amount),
                total: valueToFixedTwoDigits(lineTotal),
                // purchaseOrderLineUuid: getStringFromObject('orderLineUuid', line, line.uuid),
                totalTax: valueToFixedTwoDigits(totalTax),
            };
            delete lineDto.uuid;
            invoiceLines.push(lineDto);
        });
    }

    uiObject.discountAmount = NumberOf(valueToFixedTwoDigits(discount));
    uiObject.subTotal = NumberOf(valueToFixedTwoDigits(NumberOf(subTotal)));
    uiObject.amountTotal = NumberOf(valueToFixedTwoDigits(NumberOf(total) - discount));
    uiObject.accountInvoiceLines = invoiceLines;
    console.log('invoicellines', uiObject);
    return uiObject;
};
