import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import cloneDeep from 'clone-deep';
import classNames from 'classnames';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import axios from 'axios';
import deepEqual from 'react-fast-compare';
import {
    reduxForm,
    Field,
    initialize,
} from 'redux-form';
import API from '../../../constants/api';
import {
    apiCatchBlockFunction,
    extractTextFromDoubleQuotes,
    getIndexFromFieldName, getJsonPath,
    isArrayValidAndNotEmpty,
    isEmpty,
    roundedValue,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import ActionButton from '../../ActionButton/ActionButton';
import {
    APPLICATION_CONFIG_URL, BOOK_MANAGEMENT_PRIVILEGES, PAGE,
    SIZE,
} from '../../../constants/constants';
import DrugBarcodeEntry from '../InternalMovesDialog/DrugBarcodeEntry';
import { getIndexToAdd, PRODUCT_CODE_FIELD } from '../InternalMovesDialog/InternalMovesUtil';
import { extractAndSetValues, ACTION_HANDLERS, applyDiscountBasedOnConfig } from './MaterialReceiveHandlers';
import { convertToPlainDataMemoized, MaterialReceiveFields } from './MaterialReceiveUtil';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import {
    cancelOrRevertMaterialReceive,
    createMaterialReceiveRequest,
    fetchMaterialReceiveRequest,
    // markAsComplete,
} from '../../../redux/modules/materialReceive/materialReceive-actions';
import {
    fetchMaterialReceive,
} from '../../../redux/modules/acceptPackage/acceptPackage-actions';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import {
    mapReceiveMaterialFromUiObject,
    UI_OBJECT,
    mapMaterialReceiveToUiObject,
} from '../../../mapper/MaterialReceiveMapper';
import ReduxFormReactSelectMaterial from '../../ReduxFormReactSelectMaterial';
import ReduxFormMaterialTable from '../../FormFieldComponents/ReduxFormMaterialTable/ReduxFormMaterialTable';
import MakeBillDialog from '../MakeBillDialog/MakeBillDialog';
import ReduxFormTextField from '../../FormFieldComponents/ReduxFormTextField/ReduxFormTextField';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { getSupplierSubCompany } from '../MakeBillDialog/MakeBillDialogUtil';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import ReduxFormCheckbox from '../../FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { required, upperCaseNormalizer } from '../../../constants/FormValidations';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import PreviousPurchaseInvoiceLinesDialog
    from '../../PreviousPurchaseInvoiceLinesDialog/PreviousPurchaseInvoiceLinesDialog';
import { subCompanies } from '../../../constants/ERPConstants';
import BarcodePrintComponent from '../../BarcodePrintComponent/BarcodePrintComponent';
import GtinMapper from '../../AcceptPackagesTable/GtinMapper';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'materialReceiveDialog';

const style = () => ({
    input: {
        padding: '0 6px',
    },
    multiInput: {
        padding: '6px',
    },
    multiline: {
        padding: '0.3em 0',
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    ...dialogComponentStyles(),
});

class MaterialReceiveDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            isPaymentInvoiceClicked: false,
            print: false,
            subCompany: '',
            isEditable: false,
            isPreviousPurchaseInvoiceLinesDialogCloseOpen: false,
            previousPurchaseInvoiceLinesProduct: null,
            page: PAGE,
            size: SIZE,
            selectedRows: [],
            isLoading: false,
            createNewGtin: false,
            index: 0,
        };
    }
    componentDidMount() {
        const jsonApi = getJsonPath('/StockManagement/MaterialReceive.json', APPLICATION_CONFIG_URL);
        fetch(jsonApi).then(resp => resp.json()).then((json) => {
            this.setState({
                schema: json,
                isPaymentInvoiceClicked: false,
            });
        }).catch((error) => {
            console.error(`There has been a problem with your fetch operation:${error.message}`);
        });
        this.getMaterialReceive();
    }

    componentDidUpdate(prevProps) {
        const oldSupplier = getStringFromObject('supplier', prevProps.formValues);
        const newSupplier = getStringFromObject('supplier', this.props.formValues);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
        } else {
            const oldCashSupplierSubCompany = getStringFromObject('cashSupplierSubCompany', prevProps.formValues);
            const newCashSupplierSubCompany = getStringFromObject('cashSupplierSubCompany', this.props.formValues);
            if (!deepEqual(oldCashSupplierSubCompany, newCashSupplierSubCompany)) {
                if (newCashSupplierSubCompany) {
                    this.updateSubCompany(newCashSupplierSubCompany.key || newCashSupplierSubCompany);
                }
            }
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     console.log('componentWillReceivePropsMaterialReceiveDialog', this.props.materialReceive);
    //     console.log('componentWillReceivePropsMaterialReceiveDialog', nextProps.materialReceive);
    //     console.log('componentWillReceivePropsMaterialReceiveDialogCheck', deepEqual(nextProps.materialReceive, this.props.materialReceive));
    //     if (!deepEqual(nextProps.materialReceive, this.props.materialReceive)) {
    //         // if (isArrayValidAndNotEmpty(nextProps.materialReceive)) {
    //         this.initializeForm(nextProps.materialReceive);
    //         // }
    //     }
    // }


    onAddProductViaBarcode = async (productInfo, barcodeProductQty) => {
        console.log('asdas09du9asdu', productInfo, barcodeProductQty);
        const {
            dispatch,
            formValues,
            change,
        } = this.props;
        const { schema } = this.state;
        try {
            dispatch(showSpinner());
            const autoDiscountConfig = getStringFromObject('autoDiscountConfig', schema);
            console.log('asdas09du9asdu', productInfo, barcodeProductQty, { formValues });

            const indexToAddTo = getIndexToAdd(formValues, 'receiveMaterialLines');
            const rowValue = {};
            const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_CODE}${productInfo.produceCode}&codeField=${productInfo.codeField}`);
            const product = response.data;
            console.log('productFetched', product);
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.price = NumberOf(product.price);
            rowValue.quantity = NumberOf(barcodeProductQty);
            rowValue.taxes = product.taxes || [];
            rowValue.expiry = productInfo.expiryDate;
            rowValue.batch = productInfo.batchName;
            rowValue.trackByBatch = product.trackByBatch;
            if (
                productInfo.codeField === PRODUCT_CODE_FIELD.EAN_13 &&
                isArrayValidAndNotEmpty(product.productGtinMapDtoList)
            ) {
                const matchingProductGtinMapDto = product.productGtinMapDtoList
                    .find(productGtinMapDto => productGtinMapDto.gtin === productInfo.produceCode);
                if (isObjectValidAndNotEmpty(matchingProductGtinMapDto)) {
                    rowValue.productGtinMapDto = matchingProductGtinMapDto;
                }
            }
            const newRowValue = extractAndSetValues(applyDiscountBasedOnConfig(autoDiscountConfig, product, rowValue));
            // const newRowValue = extractAndSetValues(rowValue);
            console.log('newRowValueNewRowValue', indexToAddTo, newRowValue);
            change(`receiveMaterialLines[${indexToAddTo}]`, newRowValue);
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        }
        dispatch(hideSpinner());
    };

    onPageChange = (page) => {
        this.setState({
            page,
        });
    };

    onSizeChange = (size) => {
        this.setState({
            size,
        });
    };

    onCreateMaterialReceiveFailed = () => {
        console.log('onCreateMaterialReceiveFailed', this.state.isLoading);
        this.setState({ isLoading: false });
    }
    getSubCompanyOfSupplier = async (supplier) => {
        console.log('asd09audas09dsa', supplier);
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        this.setState({
            subCompany: subCompany || 'CLINIC',
        });
    };

    getMaterialReceive = () => {
        const {
            targetId,
            dispatch,
            formValues,
            isRsd,
            notificationId,
        } = this.props;
        const uuid = targetId || getStringFromObject('uuid', formValues);
        console.log('getMaterialReceive =>', uuid);

        if (uuid) {
            let api = '';
            if (NumberOf(targetId) === 0) {
                api = `${API.MATERIAL_RECEIVE.GET_MATERIAL_RECEIVE}${uuid}`;
            } else {
                api = `${API.MATERIAL_RECEIVE.GET_MATERIAL_RECEIVE_BY_ID}${uuid}`;
            }
            dispatch(fetchMaterialReceiveRequest(api, this.initializeForm));
        } else if (isRsd) {
            dispatch(fetchMaterialReceive(`${API.ACCEPT_PACKAGES.MATERIAL_RECEIVE}${notificationId}`, this.initializeForm));
            this.setState({ isEditable: true });
        } else {
            this.setState({ isEditable: true });
        }
    };

    updateSubCompany = (subCompany) => {
        this.setState({ subCompany });
    }
    // resetLoading = (loading = false) => this.setState({ loading: Boolean(loading) });

    printMaterialReceive = () => {
        this.setState(prevState => ({ print: !prevState.print }));
    }

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    initializeForm = (formValues) => {
        // const { materialReceive } = this.props;
        const { dispatch, isRsd } = this.props;
        // this.setState({ loading: true }, () => {
        const uiObject = mapMaterialReceiveToUiObject(formValues);
        // uiObjectPromise.then((response) => {
        console.log('mapStockIntentDtoToUiObject response', uiObject);
        dispatch(initialize(formName, uiObject));
        if (isRsd && !getStringFromObject('uuid', formValues)) {
            this.setState({ isEditable: true });
        } else {
            this.setState({ isEditable: false });
        }
        this.setState({ isLoading: false });
    };

    handleCreateNew = () => {
        this.initializeForm({ ...UI_OBJECT });
        this.setState({ isEditable: true });
    }

    handleChangeDiscountPerc = () => {
        const { change } = this.props;
        change('discount', 0);
    };

    handleChangeDiscountAmount = () => {
        const { change } = this.props;
        change('discountPercent', 0);
    };

    isConfirmed = () => {
        const {
            formValues,
        } = this.props;
        return ['DONE', 'CONFIRMED', 'COMPLETE'].indexOf(getStringFromObject(
            'status', formValues,
        )) !== -1 && formValues.uuid;
    };

    isVoided = () => {
        const {
            formValues,
        } = this.props;
        return ['REVERTED', 'CANCEL'].indexOf(getStringFromObject(
            'status', formValues,
        )) !== -1 && formValues.uuid;
    };
    handleSave = (values) => {
        this.handleSubmitForm(values, 'DRAFT');
    };

    handleConfirm = (values) => {
        this.handleSubmitForm(values, 'CONFIRM');
    };

    handleSubmitForm = (values, status) => {
        console.log('zzzz', values);
        const { dispatch } = this.props;
        console.log('fandfjkajghajkgbfajkgss', values, status === 'DRAFT');
        this.setState({ isLoading: true });
        if (isArrayValidAndNotEmpty(values.receiveMaterialLines)) {
            // eslint-disable-next-line no-param-reassign
            values = {
                ...values,
                dispatch: getStringFromObject('dispatch', values) ? getStringFromObject('dispatch', values) : this.props.notificationId,
            };
            if (status === 'CONFIRM') {
                // const api = `${API.MATERIAL_RECEIVE.CONFIRM}${getStringFromObject('uuid', values)}`;
                // console.log('handleMarkAsCompletevaluesSubmit', getStringFromObject('status', values) === 'COMPLETED', values);
                dispatch(createMaterialReceiveRequest(
                    mapReceiveMaterialFromUiObject(values, 'CONFIRMED'),
                    this.getMaterialReceive,
                    this.onCreateMaterialReceiveFailed,
                ));
            } else {
                dispatch(createMaterialReceiveRequest(
                    mapReceiveMaterialFromUiObject(values, 'DRAFT'),
                    this.initializeForm,
                    this.onCreateMaterialReceiveFailed,
                ));
            }
        } else {
            dispatch(errorMessage('There are no items'));
        }
    };


    handleCancel = async () => {
        const { dispatch, formValues } = this.props;
        const uuid = getStringFromObject('uuid', formValues);
        this.setState({ isLoading: true });
        // get cancelled material and initialize the form
        dispatch(cancelOrRevertMaterialReceive(uuid, 'CANCEL', this.getMaterialReceive));
    };
    handleRevert = async () => {
        const { dispatch, formValues } = this.props;
        const uuid = getStringFromObject('uuid', formValues);
        this.setState({ isLoading: true });
        // get new material created and initialize the form
        dispatch(cancelOrRevertMaterialReceive(uuid, 'REVERT', this.initializeForm));
    };
    handleClickPaymentInvoice = () => {
        this.setState({
            isPaymentInvoiceClicked: true,
        });
    };
    handleClosePaymentInvoice = () => {
        this.setState({
            isPaymentInvoiceClicked: false,
        }, this.getMaterialReceive);
    };
    toggleEditable = () => {
        const { isEditable } = this.state;
        if (isEditable) {
            this.getMaterialReceive();
        } else {
            this.setState({ isEditable: true });
        }
    }

    handleCashPurchaseCheck = () => {
        const { change } = this.props;
        change(`${MaterialReceiveFields.SUPPLIER}`, null);
        change(`${MaterialReceiveFields.CASH_SUPPLIER_NAME}`, '');
        change(`${MaterialReceiveFields.CASH_SUPPLIER_VAT_NUMBER}`, '');
    }

    handleInfoIconClick = (event, index) => {
        const { formValues, dispatch } = this.props;
        event.stopPropagation();
        const productUuid = getStringFromObject(`receiveMaterialLines.${index}.product.uuid`, formValues);
        if (productUuid) {
            this.setState({
                isPreviousPurchaseInvoiceLinesDialogCloseOpen: true,
                previousPurchaseInvoiceLinesProduct: {
                    uuid: productUuid,
                    name: getStringFromObject(`receiveMaterialLines.${index}.product.productName`, formValues) || '',
                },
            });
        } else {
            dispatch(errorMessage('product field empty!'));
            // setFieldError(`purchaseOrderLines.${index}.product`, 'required');
        }
    }

    handlePreviousPurchaseInvoiceLinesDialogClose = () => {
        this.setState({
            isPreviousPurchaseInvoiceLinesDialogCloseOpen: false,
            previousPurchaseInvoiceLinesProduct: null,
        });
    }

    handleRowSelection = (selected) => {
        const { formValues } = this.props;
        const receiveMaterialLines = getStringFromObject('receiveMaterialLines', formValues);
        console.log('selectedRows', { selected, formValues });
        const selectedRows = [];

        if (isArrayValidAndNotEmpty(selected) && isArrayValidAndNotEmpty(receiveMaterialLines)) {
            selected.forEach((anIndex) => {
                const item = receiveMaterialLines[anIndex];
                if (isObjectValidAndNotEmpty(item)) {
                    selectedRows.push({
                        uuid: getStringFromObject('uuid', item),
                        name: getStringFromObject('product.productName', item),
                        batch: item.batchName,
                        quantity: item.quantity,
                        state: 'QUEUED',
                    });
                }
            });
        }
        this.setState({ selectedRows });
    };

    handleChooseGtin = (productGtinMapDto, _formName, field) => {
        const { formValues, dispatch } = this.props;
        const index = getIndexFromFieldName(field);
        if (formValues.receiveMaterialLines[index].product) {
            if (isObjectValidAndNotEmpty(productGtinMapDto)) {
                if (productGtinMapDto.uuid && (typeof productGtinMapDto.uuid === 'string') && productGtinMapDto.uuid.includes('NCD-')) {
                    const gtin = extractTextFromDoubleQuotes(getStringFromObject('gtin', productGtinMapDto));
                    this.setState({
                        createNewGtin: true,
                        gtin,
                        index,
                    });
                }
            }
        } else {
            dispatch(errorMessage('You must select a product before adding Gtin'));
            this.clearGtin(index);
        }
    }

    handleCloseCreateDialog = (value) => {
        const {
            index,
        } = this.state;
        this.props.change(`receiveMaterialLines[${index}].productGtinMapDto`, value);
        this.setState({
            createNewGtin: false,
            gtin: '',
            index: 0,
        });
    }

    render() {
        const {
            open,
            handleClose,
            formValues,
            handleSubmit,
            classes,
            enableSubVendorCheckBox,
            barcodeParser,
            dispatch,
            enableBarcodePrintButt,
            focusBarcodeShortcut,
        } = this.props;
        const {
            isEditable,
            schema,
            isPaymentInvoiceClicked,
            print,
            subCompany,
            isPreviousPurchaseInvoiceLinesDialogCloseOpen,
            previousPurchaseInvoiceLinesProduct,
            page,
            size,
            isLoading,
            selectedRows,
            createNewGtin,
            gtin,
            index,
        } = this.state;
        console.log('formValues:123123123 ', formValues);
        const isConfirmed = this.isConfirmed();
        // material receive is cancelled or reverted - state => [CANCEL, REVERTED]
        // hide all dialog actions except NEW nad PRINT
        const isVoided = this.isVoided();
        console.log('🚀 - isConfirmedddd:', isConfirmed, enableBarcodePrintButt);
        const totalElements = getStringFromObject('receiveMaterialLines', formValues, []).length;
        console.log('propsAndStateMaterialReceiveDialog', isEditable, isConfirmed, isConfirmed || !isEditable, formValues);

        const receiveMaterialLines = getStringFromObject('receiveMaterialLines', formValues, []);

        const autoDiscountConfig = getStringFromObject('autoDiscountConfig', schema);

        let subTotal = 0;
        let totalTax = 0;
        let discountAmount = NumberOf(getStringFromObject('discount', formValues));
        const discountPerc = NumberOf(getStringFromObject('discountPercent', formValues));
        if (isObjectValidAndNotEmpty(formValues) && isArrayValidAndNotEmpty(receiveMaterialLines)) {
            receiveMaterialLines.forEach((line) => {
                subTotal += NumberOf(line.totalPrice);
                totalTax += NumberOf(line.totalTax);
                console.log('receiveMaterialLinesRender', {
                    line, subTotal, totalTax, lineTotal: line.totalPrice, lineTax: line.totalTax,
                });
            });
        }

        const amountWithTax = subTotal;
        if (discountPerc > 0) {
            discountAmount = roundedValue(amountWithTax * (discountPerc / 100));
        }
        const isLocationEditable = getStringFromObject(MaterialReceiveFields.SUPPLIER, formValues, false) ||
            getStringFromObject(MaterialReceiveFields.CASH_SUPPLIER_SUB_COMPANY, formValues, false);
        subTotal -= totalTax;
        const total = (subTotal + totalTax) - discountAmount;
        const isCashPurchase = getStringFromObject(`${MaterialReceiveFields.CASH_PURCHASE}`, formValues) || false;
        const allHandlers = {
            ...ACTION_HANDLERS(autoDiscountConfig),
            handleChooseGtin: this.handleChooseGtin,
        };
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    fullScreen
                >
                    <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                        <Grid container justify="space-between">
                            <div className={classes.header}>
                                Material Receive
                            </div>
                            <Close
                                className={classNames(classes.closeIcon, 'cursor-pointer')}
                                onClick={handleClose}
                                test-id="close-return-stock"
                            />
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <form>
                            <Grid container className="mt-1" spacing={32}>
                                <Grid item lg={3} sm={4} md={3}>
                                    <Field
                                        name={MaterialReceiveFields.SUPPLIER}
                                        component={ReduxFormReactSelectMaterial}
                                        label="Supplier"
                                        autocomplete
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        dataSourceApi={API.SEARCH.SUPPLIER}
                                        isDisabled={isConfirmed || !isEditable || isCashPurchase}
                                        required={!isCashPurchase}
                                        validate={isCashPurchase ? [] : [required]}
                                        testId="supplier"
                                    />
                                </Grid>
                                <Grid item lg={1} sm={3} md={2}>
                                    <Field
                                        name={MaterialReceiveFields.CASH_PURCHASE}
                                        component={ReduxFormCheckbox}
                                        label="Cash Purchase"
                                        disabled={isConfirmed || !isEditable}
                                        onChangeHandlers={['handleCashPurchaseCheck']}
                                        actionHandlers={{
                                            handleCashPurchaseCheck: this.handleCashPurchaseCheck,
                                        }}

                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchase && (
                                        <Field
                                            name={MaterialReceiveFields.CASH_SUPPLIER_NAME}
                                            label="Supplier Name"
                                            component={ReduxFormTextField}
                                            disabled={isConfirmed || !isEditable}
                                            normalize={upperCaseNormalizer}
                                            validate={[required]}
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchase && (
                                        <Field
                                            name={MaterialReceiveFields.CASH_SUPPLIER_VAT_NUMBER}
                                            label="Vat Number"
                                            component={ReduxFormTextField}
                                            disabled={isConfirmed || !isEditable}
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchase && (
                                        <Field
                                            name="cashSupplierSubCompany"
                                            label="Sub Company"
                                            component={ReduxFormReactSelectMaterial}
                                            isDisabled={isConfirmed || !isEditable}
                                            options={Object.values(subCompanies).map(aSubCompany => ({
                                                key: aSubCompany,
                                                value: aSubCompany,
                                            }))}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            fullWidth
                                            validate={required}
                                            required
                                        />
                                    )}
                                </Grid >
                                <Grid item lg={2} sm={4} md={3}>
                                    {
                                        isConfirmed &&
                                        checkIfPrivilegeExistsForUser(BOOK_MANAGEMENT_PRIVILEGES.purchaseBill) && (
                                            <div
                                                className={classNames('flo-right')}
                                            >
                                                <ActionButton
                                                    id="purchase-invoice"
                                                    disableRipple
                                                    fullWidth
                                                    onClick={this.handleClickPaymentInvoice}
                                                >
                                                    Purchase Invoice
                                                </ActionButton>
                                            </div>
                                        )
                                    }
                                    {(!isVoided && !isConfirmed && getStringFromObject('uuid', formValues)) &&
                                        (
                                            <ActionButton
                                                className={classNames('flo-right')}
                                                test-id="material-receive-misc-save"
                                                disableRipple
                                                disabled={isConfirmed}
                                                onClick={this.toggleEditable}
                                            >
                                                {isEditable ? 'Cancel Edit' : 'Edit'}
                                            </ActionButton>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container className="mt-1" spacing={32}>
                                <Grid item lg={3} sm={4} md={3}>
                                    <Field
                                        name={MaterialReceiveFields.LOCATION}
                                        component={ReduxFormReactSelectMaterial}
                                        label="Location"
                                        autocomplete
                                        validate={required}
                                        required
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        dataSourceApi={`${API.SEARCH.STOCK_LOCATION_SUB_COMPANY}${subCompany || 'CLINIC'}&searchString=`}
                                        isDisabled={(isConfirmed || !isEditable) || !isLocationEditable}
                                        testId="location"
                                    />
                                </Grid>
                                <Grid item lg={3} sm={4} md={3}>
                                    <Field
                                        testId={MaterialReceiveFields.DATE}
                                        label="Stock Date"
                                        name={MaterialReceiveFields.DATE}
                                        component={DateInput}
                                        fullWidth
                                        disabled={isConfirmed || !isEditable}
                                    />
                                </Grid>
                                {
                                    enableSubVendorCheckBox && (
                                        <Grid item lg={1} sm={3} md={2}>
                                            <Field
                                                name={MaterialReceiveFields.SUB_VENDOR}
                                                component={ReduxFormCheckbox}
                                                label="Sub Vendor"
                                                disabled={isConfirmed || !isEditable}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>

                            {(isEditable && !isConfirmed) &&
                                (
                                    <DrugBarcodeEntry
                                        dispatch={this.props.dispatch}
                                        onConfirmQuantity={this.onAddProductViaBarcode}
                                        barcodeParser={barcodeParser}
                                        enabledShortcut={focusBarcodeShortcut || ''}
                                    />
                                )}
                            <Grid container justify="flex-end">
                                {
                                    (isConfirmed) && enableBarcodePrintButt &&
                                    <BarcodePrintComponent
                                        items={selectedRows}
                                        actions={[
                                            {
                                                key: 'MATERIAL_RECEIVE_PRODUCT',
                                                value: 'PRODUCT',
                                            },
                                            {
                                                key: 'MATERIAL_RECEIVE_BATCH',
                                                value: 'BATCH',
                                            },
                                        ]}
                                    />
                                }
                            </Grid>
                            <Grid container className="mt-2">

                                {
                                    isObjectValidAndNotEmpty(schema) &&
                                    <ReduxFormMaterialTable
                                        fieldName={MaterialReceiveFields.PRODUCTS}
                                        rowsPerPage={SIZE}
                                        internalPagination
                                        actionHandlers={allHandlers}
                                        dispatch={this.props.dispatch}
                                        styles={{ minHeight: '20em' }}
                                        rerenderOnEveryChange
                                        {...schema}
                                        tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                        isEditable={(!isConfirmed && isEditable)}
                                        forceEnableCheckBox
                                        paramMap={formValues.receiveMaterialLines}
                                        formValues={formValues}
                                        infoIcon
                                        enablePagination
                                        handleInfoIconClick={this.handleInfoIconClick}
                                        paginationDetails={{
                                            page,
                                            size,
                                            totalElements,
                                        }}
                                        handlePageChange={this.onPageChange}
                                        handleSizeChange={this.onSizeChange}
                                        rowsPerPageOptions={[10, 20]}
                                        handleRowSelection={this.handleRowSelection}
                                    />
                                }
                            </Grid>
                            <Grid container className="mt-2" spacing={16}>
                                <Grid item sm={3}>
                                    <Field
                                        name={MaterialReceiveFields.NOTES}
                                        label="notes"
                                        component={ReduxFormTextField}
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        fullWidth
                                        disabled={isConfirmed || !isEditable}
                                    />
                                </Grid>
                                <Grid item sm={4} />
                                <Grid item sm={3}>
                                    <Grid container spacing={8}>
                                        <Grid item sm={6}>
                                            <Field
                                                label="Discount (%)"
                                                name={MaterialReceiveFields.DISCOUNT_PERCENT}
                                                component={ReduxFormTextField}
                                                type="number"
                                                onChangeHandlers={['handleChangeDiscountPerc']}
                                                actionHandlers={{
                                                    handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                }}
                                                disabled={isConfirmed || !isEditable}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Field
                                                label="Discount Amount"
                                                name={MaterialReceiveFields.DISCOUNT}
                                                type="number"
                                                component={ReduxFormTextField}
                                                onChangeHandlers={['handleChangeDiscountAmount']}
                                                actionHandlers={{
                                                    handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                }}
                                                disabled={isConfirmed || !isEditable}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={2}>
                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                        Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(subTotal)}
                                    </div>
                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                        Tax:&nbsp;&nbsp;{valueToFixedTwoDigits(totalTax)}
                                    </div>
                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                        Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(discountAmount)}
                                    </div>
                                    <div className={classNames('mt-1', classes.totalField)}>
                                        Total:&nbsp;&nbsp;{valueToFixedTwoDigits(total)}
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {
                            // hide all the actions if it is cancelled or voided
                            !isVoided &&
                            <React.Fragment>
                                {
                                    formValues.location && !isConfirmed &&
                                    <div>
                                        <Tooltip
                                            title="This will cancel the material receive and the action is irreversible"
                                        >
                                            <ActionButton
                                                disabled={isLoading}
                                                onClick={this.handleCancel}
                                            >
                                                Cancel
                                            </ActionButton>
                                        </Tooltip>
                                        <ActionButton
                                            testId="material-receive-misc-save"
                                            className="ml-1"
                                            disableRipple
                                            disabled={isConfirmed || isLoading}
                                            onClick={handleSubmit(this.handleSave)}
                                        >
                                            Save
                                        </ActionButton>
                                        <ActionButton
                                            testId="material-receive-misc-confirm"
                                            disableRipple
                                            disabled={
                                                !getStringFromObject('uuid', formValues)
                                                || isEditable
                                                || isLoading
                                            }
                                            className="ml-1"
                                            onClick={handleSubmit(this.handleConfirm)}
                                        >
                                            Confirm
                                        </ActionButton>
                                    </div>
                                }
                                {
                                    isConfirmed &&
                                    <div>
                                        <Tooltip
                                            title="This will revert the material receive and the action is irreversible"
                                        >
                                            <ActionButton
                                                onClick={this.handleRevert}
                                                className="ml-1"
                                                disabled={isLoading}
                                            >
                                                Revert
                                            </ActionButton>
                                        </Tooltip>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        {
                            (getStringFromObject('uuid', formValues)) && (
                                <div>
                                    <ActionButton
                                        className="ml-1"
                                        onClick={this.printMaterialReceive}
                                        disabled={isLoading}
                                    >
                                        Print
                                    </ActionButton>
                                    <ActionButton
                                        className="ml-1"
                                        onClick={this.handleCreateNew}
                                        disabled={isLoading}
                                    >
                                        New
                                    </ActionButton>
                                </div>
                            )
                        }
                    </DialogActions>
                </Dialog>
                {
                    isPaymentInvoiceClicked &&
                    <MakeBillDialog
                        open
                        materialReceive={formValues}
                        handleClose={this.handleClosePaymentInvoice}
                    />

                }
                {
                    isPreviousPurchaseInvoiceLinesDialogCloseOpen &&
                    isObjectValidAndNotEmpty(previousPurchaseInvoiceLinesProduct) && (
                        <PreviousPurchaseInvoiceLinesDialog
                            onClose={this.handlePreviousPurchaseInvoiceLinesDialogClose}
                            productUuid={previousPurchaseInvoiceLinesProduct.uuid}
                            productName={previousPurchaseInvoiceLinesProduct.name}
                        />
                    )
                }
                <Print
                    data={convertToPlainDataMemoized(formValues)}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/MaterialReceive/MaterialReceive.html`}
                    print={print}
                    subCompany={subCompany}

                />
                {
                    createNewGtin &&
                    <GtinMapper
                        open
                        header={getStringFromObject('productName', formValues.receiveMaterialLines[index].product)}
                        product={formValues.receiveMaterialLines[index].product}
                        handleClose={this.handleCloseCreateDialog}
                        dispatch={dispatch}
                        gtin={gtin}
                    />
                }
            </React.Fragment>
        );
    }
}
MaterialReceiveDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    change: PropTypes.func,
    formValues: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    targetId: PropTypes.number,
    state: PropTypes.object,
    classes: PropTypes.object,
    isRsd: PropTypes.bool,
    notificationId: PropTypes.number,
    enableSubVendorCheckBox: PropTypes.bool,
    barcodeParser: PropTypes.string,
    enableBarcodePrintButt: PropTypes.bool,
    focusBarcodeShortcut: PropTypes.string,

};
MaterialReceiveDialog.defaultProps = {
    dispatch: () => { },
    formValues: {},
    change: () => { },
    targetId: null,
    state: {},
    classes: {},
    focusBarcodeShortcut: '',
    isRsd: false,
    notificationId: null,
    barcodeParser: '',
    enableSubVendorCheckBox: false,
    enableBarcodePrintButt: false,
};


const mapStateToProps = state => ({
    formValues: state.form[formName] ? state.form[formName].values : {},
    state,
    focusBarcodeShortcut: getStringFromObject('appConfiguration.materialReceiveShortcuts.focusBarcode', state),
    enableSubVendorCheckBox:
        getStringFromObject('appConfiguration.enableSubVendorCheckBox', state),
    barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
    enableBarcodePrintButt: getStringFromObject('appConfiguration.enableBarcodePrintButton', state) || false,
});


export default connect(mapStateToProps)(reduxForm({
    initialValues: cloneDeep(UI_OBJECT),
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(withStyles(style)(MaterialReceiveDialog)));
